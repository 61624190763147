import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  width: 14px;
  height: 14px;
  margin-top: 2px;
`;

const CartIcon = props => (
  <Icon
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    fill={props.fill}
  >
    <g>
      <path d="M3.21,2.83c.2.79,1.27,5.48,1.36,5.93a1,1,0,0,0,1.09.95h6.8a.29.29,0,0,0,0-.57H5.65c-.34,0-.45-.11-.53-.49l-.07-.32L12,7a1.29,1.29,0,0,0,1-1.28V3.62a1.06,1.06,0,0,0-1.06-1H3.72c-.22-.71-.61-1.14-1.2-1.14H1.29a.29.29,0,1,0,0,.57H2.52c.18,0,.47,0,.68.83" />
      <path d="M12.57,11.28a1.29,1.29,0,1,0-1.29,1.29,1.29,1.29,0,0,0,1.29-1.29" />
      <path d="M5.86,10a1.29,1.29,0,1,0,1.29,1.29A1.29,1.29,0,0,0,5.86,10" />
    </g>
  </Icon>
);

export default CartIcon;
