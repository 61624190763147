export const checkStockLevels = async productSku => {
  return await fetch('/.netlify/functions/getLiveInventoryLevels', {
    method: 'POST',
    body: JSON.stringify({
      productSku,
    }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })
    .then(response => response.json())
    .catch(error => console.log(error));
};
