import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import CartIcon from '../icons/cart-icon';

// Cart
import { useShoppingCart } from 'use-shopping-cart';

// Components
import ContentContainer from '../global/contentContainer';
import Title from '../text/title';
import Subtitle from '../text/subtitle';
import Textbox from '../text/textbox';
import SingleImage from '../images/singleImage';

// Utils
import formatPrice from '../utility/formatPrice';
import { checkStockLevels } from '../global/check-stock-levels';

const PriceContainer = styled.div`
  font-size: 20px;
  display: flex;

  margin: 0 0 75px 0;
  color: ${props => props.color};

  & > p:after {
    content: ' ';
  }

  @media (max-width: 767px) {
    margin: 0 9px;
  }

  @media (max-width: 576px) {
    font-size: 15px;
  }
`;

const AddToCart = styled.div`
  cursor: pointer;
  :hover {
    filter: invert(100%);
  }
`;

const Price = styled.p`
  margin: 0;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  :hover {
    filter: invert(100%);
  }
`;

const SoldOutPrice = styled(Price)`
  cursor: initial;

  :hover {
    filter: unset;
  }
`;

const SoldOutAddToCart = styled(AddToCart)`
  cursor: initial;

  :hover {
    filter: unset;
  }

  @media (max-width: 576px) {
    font-size: 15px;
  }
`;

const Theme = {
  highlightColor: '#d38296',
  textColor: '#ffffff',
};

const ContainerTheme = {
  backgroundColor: '#ffdce6',
};

const Product = ({ data }) => {
  const { addItem } = useShoppingCart();

  const [product, setProduct] = useState([]);
  const [relatedEvents, setRelatedEvents] = useState([]);
  const [activePrice, setActivePrice] = useState('full_price');
  const [stockLevel, setStockLevel] = useState({
    isStockSet: false,
    stockLevel: null,
    stockType: '',
  });

  async function getStocksLevels() {
    const stockLevels = await checkStockLevels(
      `sku_${data.prismicProduct.prismicId}`
    );

    setStockLevel({
      isStockSet: true,
      stockLevel: stockLevels.currentInventory.inventory.quantity,
      stockType: stockLevels.currentInventory.inventory.type,
    });
  }

  useEffect(() => {
    const formattedProductData = {
      name:
        activePrice === 'full_price'
          ? data.prismicProduct.data.title.text
          : `${data.prismicProduct.data.title.text} – Members Discount`,
      id:
        activePrice === 'full_price'
          ? `sku_${data.prismicProduct.prismicId}`
          : `sku_${data.prismicProduct.prismicId}_discounted`,
      price:
        activePrice === 'full_price'
          ? data.prismicProduct.data.full_price * 100
          : data.prismicProduct.data.discounted_price * 100,
      currency: 'GBP',
      image: data.prismicProduct.data.image,
      uid: `/shop/product/${data.prismicProduct.uid}`,
      details: data.prismicProduct.data,
      productType: `goods`,
    };

    setProduct(formattedProductData);
  }, [data, activePrice, setProduct]);

  useEffect(() => {
    getStocksLevels();
  }, []);

  return (
    <>
      <Helmet>
        <title>{data.prismicProduct.data.title.text}</title>
        <meta name="title" content={data.prismicProduct.data.title.text} />
        <meta name="description" content={data.prismicProduct.data.text.text} />
        <meta
          property="og:url"
          content={'https://www.draw-brighton.co.uk/' + data.prismicProduct.uid}
        />
        <meta
          property="og:image"
          content={data.prismicProduct.data.image.url}
        />
        <meta
          property=" og:image:secure_url"
          content={data.prismicProduct.data.image.url}
        />
        <meta
          property="og:description"
          content={data.prismicProduct.data.text.text}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:locale" content="en" />
        <meta
          name="twitter:title"
          content={data.prismicProduct.data.title.text}
        />
        <meta
          name="twitter:description"
          content={data.prismicProduct.data.text.text}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content={data.prismicProduct.data.image.url}
        />
      </Helmet>

      <Row justifyContent="center" css={ContainerTheme}>
        <Col col={12} md={10}>
          <ContentContainer padding={'0 36px'} mobilePadding={'40px 9px'}>
            <Title text={data.prismicProduct.data.title.text} />
            <Subtitle
              text={data.prismicProduct.data.subtitle}
              color={Theme.highlightColor}
              case={'inherit'}
              mobileMargin={'20px 0 0 0'}
            />
          </ContentContainer>
        </Col>
        <Col col={12} md={10} lg={8}>
          <ContentContainer padding={'0 36px'} mobilePadding={'0 9px'}>
            <SingleImage image={data.prismicProduct.data.image.url} />
          </ContentContainer>
        </Col>
        <Textbox
          text={data.prismicProduct.data.text.html}
          singleTextboxMargin={'0 0 20px 0'}
          mobileBottomMargin={'0 9px 20px 9px'}
        />
        <Col col={12} md={10} lg={10}>
          <Row justifyContent="center">
            <Col col={12} sm={9} md={9} lg={7} xl={6}>
              <PriceContainer color={Theme.highlightColor}>
                <React.Fragment>
                  <Price>
                    {formatPrice(data.prismicProduct.data.full_price, 'gbp')}
                  </Price>
                  <AddToCart onClick={() => addItem(product)}>
                    Add to Cart <CartIcon fill={Theme.highlightColor} /> +
                  </AddToCart>
                </React.Fragment>
              </PriceContainer>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Product;

export const productQuery = graphql`
  query ProductsBySlug($uid: String!) {
    prismicProduct(uid: { eq: $uid }) {
      uid
      prismicId
      data {
        title {
          text
        }
        subtitle
        image {
          copyright
          url
          fluid {
            srcWebp
          }
        }
        text {
          html
          text
        }
        full_price
        initial_number_of_places
        product_details_1
        product_details_2
        product_details_3
        product_details_4
      }
    }
    allPrismicProduct(limit: 3, filter: { uid: { ne: $uid } }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            subtitle
            image {
              copyright
              url
            }
            text {
              html
            }
            full_price
            initial_number_of_places
          }
        }
      }
    }
  }
`;
